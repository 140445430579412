<template>
  <main class="mt-40 mx-40">
    <div class="row">
      <div class="col-md-6 mb-40">
        <div class="my-60"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/nn9RihOnLuc"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-40">
        <h5 class="mt-5 mb-40">¿QUÉ OFRECE LA FERIA?</h5>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="box">
                  <Check color="orange" />
                  <div class="box-text">Stand Presencial y Virtual</div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="box">
                  <Check color="orange" />
                  <div class="box-text">Charlas Presenciales y Webinars</div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="box">
                  <Check color="green" />
                  <div class="box-text">
                    Entrevistas Programadas y Espontaneas
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="box">
                  <Check color="blue" />
                  <div class="box-text">Oportunidades Laborales</div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="box">
                  <Check color="orange" />
                  <div class="box-text">
                    Recepción de CV Presencial y Virtual
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5 class="mt-5 mb-20">INSCRIPCIONES</h5>
        <div class="row py-20 d-flex justify-content-left">
          <div
            class="col-md-10 label d-flex justify-content-left"
            style="cursor: pointer"
          >
            <Label color="orange" text="POSTULANTE" link="/applicants" />
          </div>
        </div>
        <div v-if="false" class="row py-20 d-flex justify-content-left">
          <div
            class="col-md-10 label d-flex justify-content-left"
            style="cursor: pointer"
          >
            <Label color="#006EB6" text="TALLERES" link="/talks" />
          </div>
        </div>
        <div class="row py-20 d-flex justify-content-left">
          <div
            class="col-md-10 label d-flex justify-content-left"
            style="cursor: pointer"
          >
            <Label color="green" text="EXPOSITOR" link="/exhibitors" />
          </div>
        </div>
      </div>
    </div>
    <ExhibitorsCarousel />
  </main>
</template>

<script>
import Check from "@/components/Check.vue";
import Label from "@/components/Label.vue";
import ExhibitorsCarousel from "@/components/ExhibitorsCarousel.vue";

export default {
  components: {
    Check,
    ExhibitorsCarousel,
    Label,
  },
};
</script>

<style lang="sass">
.box *
  display: inline-block
  margin: 10px 0

.box-text
  margin-left: 10px
  position: absolute
</style>
